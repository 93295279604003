import React, { useState, useEffect} from "react";
import styled from "styled-components";

const StyledWrapper = styled.div`
  .year-selected {
    font-size: 16px;
    font-weight: 800;
  }

  .year-selected:hover {
    border-bottom: 2px solid #f2b542;
    cursor: pointer;
  }

  .underline-year {
    border-bottom: 2px solid #f2b542;
  }

  .img-class {
    height: 400px;
    width: 100%;
    object-fit: cover;
    object-position: top;
    @media (max-width: 600px) {
      height: 300px;
    }
    padding-bottom: 30px;
  }

  .header-text {
    font-family: ${props => props.theme["primaryFont"]} !important;
  }
`;

const Images = ({ data }) => {
  const year = new Date().getFullYear();
  const [yearSelected, setYearSelected] = useState(Number(year));
  const mainUrl = process.env.GATSBY_API_URL;
  const [images, setImages] = useState([]);

  useEffect(() => {
    const currentYearImages = data.filter(item => Number(item.year) === Number(yearSelected));
    if (currentYearImages.length > 0) {
      setImages(currentYearImages);
    } else {
      const previousYearImages = data.filter(item => Number(item.year) === Number(yearSelected - 1));
      if(previousYearImages.length > 0){
      setImages(previousYearImages);
      }
      else{
        const maxYear = Math.max(...data.map(item => Number(item.year)));
        const latestYearImages = data.filter(item => Number(item.year) === maxYear);
        setImages(latestYearImages);
      }
    }
  }, [data, yearSelected]);

  return (
    <StyledWrapper className="padding-left-8 padding-right-8 py-5 white-background">
      <div className="row">
        {/* <div className="col-6">
          <h1 className="header-text">Event Photos</h1>
        </div> */}
        {/* <div className="col-6">
          <div className="d-flex justify-content-end mb-2">
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == year ? "underline-year" : " ")
              }
              onClick={() => setYearSelected(year)}
            >
              {year}
            </div>
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2019" ? "underline-year" : "")
              }
              onClick={() => setYearSelected("2019")}
            >
              2019
            </div>
            <div
              className={
                "mr-3 year-selected " +
                (yearSelected == "2021" ? "underline-year" : "")
              }
              onClick={() => setYearSelected("2021")}
            >
              2021
            </div>
            <div
              className={
                "year-selected " +
                (yearSelected == "2022" ? "underline-year" : "")
              }
              onClick={() => setYearSelected("2022")}
            >
              2022
            </div>
          </div>
        </div> */}
      </div>
      <div className="row">
        {images.length > 0 &&
          images
            .map(
              item =>
                item.image && (
                  <div
                    className={
                      item.size === "small"
                        ? "col-lg-4"
                        : item.size === "large"
                        ? "col-lg-12"
                        : "col-lg-6"
                    }
                  >
                    <img
                      className="img-fluid img-class"
                      src={mainUrl + item.image.url}
                      alt=""
                    />
                  </div>
                )
            )}
      </div>
      {/* <div className="d-flex justify-content-center">
        <div>
          {yearSelected === "2018"
            ? "With thanks to Alexander Wenk for photography at the Ceremony"
            : yearSelected === "2019"
            ? "With thanks to Dominic De Vere for photography at the Ceremony"
            : yearSelected === "2021"
            ? "With thanks to Jess Govinden and Sarah Gregory for photography at the Ceremony"
            : "With thanks to Deimante Aleksandraviciute and Ziggy Johannsen for photography at the Ceremony"}
        </div>
      </div> */}
    </StyledWrapper>
  );
};

export default Images;
